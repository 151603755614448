import { useState, useCallback } from "react";
import PopUpData from "../components/PopUpData";
import PortalPopup from "../components/PortalPopup";
import styles from "./AccountBloccato.module.css";

const AccountBloccato = () => {
  const [isPopUpDataOpen, setPopUpDataOpen] = useState(false);

  const openPopUpData = useCallback(() => {
    setPopUpDataOpen(true);
  }, []);

  const closePopUpData = useCallback(() => {
    setPopUpDataOpen(false);
  }, []);

  return (
    <>
      <div className={styles.accountbloccato}>
        <div className={styles.king1Parent}>
          <img className={styles.king1Icon} alt="" src="/cuore.svg" />
          <h2 className={styles.accountBloccato} onClick={openPopUpData}>
            Account Bloccato
          </h2>
        </div>
      </div>
      {isPopUpDataOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closePopUpData}
        >
          <PopUpData onClose={closePopUpData} />
        </PortalPopup>
      )}
    </>
  );
};

export default AccountBloccato;
