

import React, { useContext } from 'react'
import { useEffect, useState } from "react";
import LoadingOverlay from 'react-loading-overlay';
import Swal from 'sweetalert2'
import DatePicker, { registerLocale } from "react-datepicker";

import it from "date-fns/locale/it"; // the locale you want
registerLocale("it", it);
import Select from 'react-select'



import userData from '../../contexts/auth';


import {  Rdb } from "../../fire";
import { ref, set, child, get, update, push } from "firebase/database";
import "react-datepicker/dist/react-datepicker.css";
import styles from "../PopUpData.module.css";

const Appuntamento = ({ onClose, Persona }) => {
  const data = useContext(userData)

//    Azienda: Persona.id,
//AziendaNome: Persona.row["Nome Attività"],
  const [dati, setDati] = useState({
    Data: new Date,
    Esitato: false,
    Otorino: "",
    OtorinoId: "",
    Nome: Persona.row["Nome"],
    Telefono: Persona.row["Telefono"],


  });
  const [loading, setLoading] = useState(false);






  const handleChange = (event) => {
    const value = event.target.value;
    setDati({ ...dati, [event.target.name]: value });

  };

  //push dati in db as appuntamento in /appuntamenti 

  const setAppuntamento = () => {
    setLoading(true);
     const newAppuntamentoRef = ref(Rdb, "appuntamentiotorino");
    push(newAppuntamentoRef, dati)
      .then(() => {
        console.log("Data saved successfully!");
        setLoading(false);
        onClose();
        Swal.fire({
          icon: 'success',
          title: 'Appuntamento aggiunto',
          showConfirmButton: false,
          timer: 1500
        })
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        onClose();
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Qualcosa è andato storto!',
        })
      });
  };


  //get otorini from db in /users where ruolo = otorino
  const [otorini, setOtorini] = useState([]);
  useEffect(() => {
    const otoriniRef = ref(Rdb, "account");
    get(otoriniRef).then((snapshot) => {
      if (snapshot.exists()) {
        let otor = snapshot.val();
        let otoriniDati = [];
        for (let id in otor) {
          console.log(otor[id].Tipo);
          if (otor[id].Tipo == "otorino") {
            otoriniDati.push({
              value: id,
              label: otor[id].Nome + " " + otor[id].Cognome,
            });
          }
        }
        setOtorini(otoriniDati);
      } else {
        console.log("No data available");
      }
    });
  }, []);

 




  return (
    <LoadingOverlay
      active={loading}
      spinner
    >
      <div className={styles.popupdata} >
        <div className={styles.modificaDati}>Nuovo Appuntamento</div>

        <div className={styles.inputsection}>


          <div style={{ flexDirection: "column", minHeight: "50vh",alignContent:"center" }}>


            <h3 className={styles.testo} >Da eseguire il</h3>
            <DatePicker locale="it" selected={new Date(Date.parse(dati.Data))} className={styles.inputsectionChild} onChange={(date) => setDati({ ...dati, Data: date.toString() })} showTimeSelect dateFormat={"dd/MM/yy HH:mm"}
            />


            <h3 className={styles.testo}> Otorino</h3>
            <Select placeholder="Seleziona Otorino"
              noOptionsMessage={({ inputValue }) => !inputValue ? noOptionsText : " Nessun risultato"}
              className={styles.inputsectionChild2}
              options={otorini}
              onChange={(choice) => setDati({ ...dati,OtorinoId: choice.value, Otorino: choice.label })}

              name="Azienda"
              id="Azienda" />


          </div>



        </div>



        <div className={styles.frameParent}>
          <button className={styles.textWrapper} onClick={onClose}>
            <h4 className={styles.text}>Annulla</h4>
          </button>
          <button className={styles.textContainer} onClick={() => setAppuntamento()}>
            <h4 className={styles.text}>Conferma</h4>
          </button>
        </div>
      </div>
    </LoadingOverlay>
  );
};

export default Appuntamento;
