import React, { useContext } from 'react'
import { useEffect, useState } from "react";

import LoadingOverlay from 'react-loading-overlay';
import Swal from 'sweetalert2'
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";

import userData from '../../contexts/auth';


import { auth2, Rdb } from "../../fire";
import { ref, set, child, get, update } from "firebase/database";


import styles from "../PopUpData.module.css";

const NuovoUtente = ({ onClose }) => {
  const data = useContext(userData)

  const [dati, setDati] = useState({
    Tipo: "formatore"
  });
  const [loading, setLoading] = useState(false);



  const handleChange = (event) => {
    const value = event.target.value;
    setDati({ ...dati, [event.target.name]: value });

  };
  function updatedata() {
    Swal.fire({
      title: "Vuoi aggiungere questo utente?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Conferma',
      confirmButtonColor: "#08174d",
      denyButtonText: `No`,
      reverseButtons: true

    }).then((result) => {

      if (result.isConfirmed) {



        setLoading(true);
        console.log(dati)

        createUserWithEmailAndPassword(auth2, dati.Email, dati.Password)
          .then((userCredential) => {
            // Signed in 
            const user = userCredential.user;

            const userRef = ref(Rdb, "account/" + user.uid);
            delete dati.Password
            const datiPlus = {
              ...dati,
              id: user.uid
            }
            set(userRef, datiPlus).then(() => {
              setLoading(false);
              onClose()
            })
              .catch((error) => {
                setLoading(false);
                onClose()
                console.log(error)

              })
          })
          .catch((error) => {

            console.log(error)
            onClose()
            // ..
          });


        //   })
      }
      else {

      }
    })

  }

  return (
    <LoadingOverlay
      active={loading}
      spinner
    >
      <div className={styles.popupdata}>
        <div className={styles.modificaDati}>Aggiungi Utente</div>

        <div className={styles.inputsection}>
          <div style={{ flexDirection: "column" }}>

            <h3 className={styles.testo}>Nome</h3>
            <input
              className={styles.inputsectionChild}
              name="Nome"
              value={dati.Nome || ''}
              type="text"
              placeholder="Nome"

              onChange={handleChange}
            />
          </div>
          <div style={{ flexDirection: "column" }}>

            <h3 className={styles.testo}>Cognome</h3>
            <input
              className={styles.inputsectionChild}
              onChange={handleChange}
              defaultValue={dati.Cognome || ''}
              type="text"
              placeholder="Cognome"
              name="Cognome"

            />
          </div>



        </div>
        <div className={styles.inputsection}>
          <div style={{ flexDirection: "column" }}>

            <h3 className={styles.testo}>Email</h3>
            <input
              className={styles.inputsectionChild}
              onChange={handleChange}
              defaultValue={dati.Email || ''}
              type="email"
              placeholder="Email"
              name="Email"

            />
          </div>

          <div style={{ flexDirection: "column" }}>

            <h3 className={styles.testo}>Password</h3>
            <input
              className={styles.inputsectionChild}
              onChange={handleChange}
              defaultValue={dati.password || ''}
              type="password"
              placeholder="Password"
              name="Password"

            />
          </div>




        </div>
        <div style={{ flexDirection: "column" }}>


          <h3 className={styles.testo} >Ruolo</h3>
          <select name="Tipo" id="Tipo" className={styles.inputsectionChild} placeholder="Ruolo" defaultValue="formatore" onChange={handleChange}>

            <option value="otorino">Otorino</option>
            <option value="amministrazione">Amministrazione</option>
            <option value="partner">Partner</option>
            <option value="farmacia">Farmacia</option>

            {
              data.Tipo == "admin" ?
                <option value="admin">Admin</option>
                :
                null
            }


          </select>
        </div>








        <div className={styles.frameParent}>
          <button className={styles.textWrapper} onClick={onClose}>
            <h4 className={styles.text}>Annulla</h4>
          </button>
          <button className={styles.textContainer} onClick={() => updatedata()}>
            <h4 className={styles.text}>Conferma</h4>
          </button>
        </div>
      </div>
    </LoadingOverlay>
  );
};

export default NuovoUtente;
