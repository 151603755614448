import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import Home from "./pages/Home";
import HomePartner from "./pages/HomePartner";
import Sospeso from "./pages/AccountBloccato";
import AreaRiservata from "./pages/AreaRiservata";
import Calendario from "./pages/Calendario";
import CalendarioPartner from "./pages/CalendarioPartner";
import Farmacie from "./pages/Farmacie";
import Analytics from "./pages/Analytics";

import TabellaUtenti from "./pages/TabellaUtenti";
// import Impostazioni from "./pages/Impostazioni";

import Login from "./pages/Login";

import { auth, Rdb } from "./fire";
import { ref, set, child, get, update } from "firebase/database";

import authContext from "./contexts/auth";

import { useEffect, useState } from "react";
import Otorino from "./pages/Otorino";
import TabellaAziendaPartner from "./pages/TabellaAziendePartner";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;
  const [initializing, setInitializing] = useState(true);
  const [user, setUser] = useState({});

  const permessi = {};

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
      case "/nuovolead":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  Auth();

  function Auth() {
    // Set an initializing state whilst Firebase connects

    // Handle user state changes
    function onAuthStateChanged(user) {
      if (user) {
        let userData = {};
        get(ref(Rdb, "account/" + user.uid)).then((snapshot) => {
          userData = snapshot.val();
          userData = {
            ...userData,
            id: user.uid,
          };
          setUser(userData);
          if (initializing) setInitializing(false);
        });
      } else {
        if (initializing) setInitializing(false);
        setUser(null);
      }
    }

    useEffect(() => {
      const subscriber = auth.onAuthStateChanged(onAuthStateChanged);
      return subscriber; // unsubscribe on unmount
    }, []);
  }

  return (
    <authContext.Provider value={user}>
      {initializing ? null : user?.sospeso ? (
        <Sospeso />
      ) : (
        <Routes>
          <Route
            path="/"
            element={
              user ? (
                user.Tipo == "admin" || user.Tipo == "amministrazione" ? (
                  <Home />
                ) : user.Tipo == "otorino" ? (
                  <Otorino />
                ) : user.Tipo == "farmacia" ? (
                  <Farmacie />
                ) : (
                  <HomePartner />
                )
              ) : (
                <Login />
              )
            }
          />
          <Route path="/otorini" element={user ? <Otorino /> : <Login />} />

          <Route path="/analytics" element={user ? <Analytics /> : <Login />} />
          <Route path="/login" element={user ? <Home /> : <Login />} />
          <Route path="/farmacie" element={user ? <Farmacie /> : <Login />} />

          <Route
            path="/tabellatask"
            element={user ? <TabellaUtenti /> : <Login />}
          />
          <Route
            path="/calendario"
            element={
              user ? (
                user.Tipo == "admin" || user.Tipo == "amministrazione" ? (
                  <Calendario />
                ) : (
                  <CalendarioPartner />
                )
              ) : (
                <Login />
              )
            }
          />

          <Route
            path="/aziende"
            element={
              user ? (
                user.Tipo == "admin" || user.Tipo == "amministrazione" ? (
                  <TabellaAziendaPartner />
                ) : (
                  <AreaRiservata />
                )
              ) : (
                <Login />
              )
            }
          />

          <Route
            path="/utenti"
            element={
              user ? (
                user.Tipo == "admin" || user.Tipo == "amministrazione" ? (
                  <TabellaUtenti />
                ) : (
                  <AreaRiservata />
                )
              ) : (
                <Login />
              )
            }
          />
          {/* <Route path="/impostazioni" element={user ?
                user.Tipo == "admin" || user.Tipo == "amministrazione" ?
                  <Impostazioni />
                  :
                  <AreaRiservata />

                : <Login />} /> */}
        </Routes>
      )}
    </authContext.Provider>
  );
}
export default App;
