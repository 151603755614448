import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import { getAuth, signOut } from "firebase/auth";


import userData from '../contexts/auth';

import styles from "./Popovermenu.module.css";

const Popovermenu = ({ onClose }) => {
  const data = useContext(userData)
  const navigate = useNavigate()

  function signOutFire() {
    const auth = getAuth();
    signOut(auth).then(() => {
      // Sign-out successful.
    }).catch((error) => {
      // An error happened.
    });
  }

  return (
    <div className={styles.popovermenu}>
      <h3 className={styles.nome}>{data.Nome} {data.Cognome}</h3>
      <h4 className={styles.email}>{data.Email}</h4>
      <img className={styles.popovermenuChild} alt="" src="../line-2@1x.png" />
      <button className={styles.homebutton} onClick={() => navigate("/")}>

        <img
          className={styles.icons8HomePage601}
          alt=""
          src="../icons8homepage60-1@1x.png"
        />
        <h3 className={styles.home}>Home</h3>
      </button>
      <button className={styles.homebutton} onClick={() => navigate("/impostazioni")}>
        <img
          className={styles.icons8HomePage601}
          alt=""
          src="../icons8homepage60-11@1x.png"
        />
        <h4 className={styles.home}>Impostazioni</h4>
      </button>
      <button className={styles.logoutbtn} onClick={signOutFire}>
        <h3 className={styles.home}>Logout</h3>
      </button>
    </div>
  );
};

export default Popovermenu;
