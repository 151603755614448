import { useEffect, useState } from "react";
import { doc, updateDoc } from "firebase/firestore";
import LoadingOverlay from 'react-loading-overlay';

import Swal from 'sweetalert2'


import { v4 } from "uuid"
import { ref, set, child, get, update, remove } from "firebase/database";

import { Rdb } from "../../fire";


import styles from "../PopUpData.module.css";

const PopUpData = ({ onClose, Persona }) => {
  delete Persona.row.actions;

  const [dati, setDati] = useState({
    Nome:Persona.row.Nome,
    Telefono:Persona.row.Telefono,
    Otorino:Persona.row.Otorino,
    OtorinoId:Persona.row.OtorinoId,
    Data:Persona.row.Data,
    Esitato:true,
    DataEsitato:new Date().toString(),
    id:Persona.id
  });
  const [loading, setLoading] = useState(false);



  const handleChange = (event) => {
    const value = event.target.value;
    setDati({ ...dati, [event.target.name]: value });

  };


  function updatedata() {
    Swal.fire({
      title: "Vuoi esitare questa persona?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Conferma',
      confirmButtonColor: "#28b0b0",
      denyButtonText: `No`,
      reverseButtons: true

    }).then((result) => {

      if (result.isConfirmed) {

        setLoading(true);
        const utenteRef = ref(Rdb, "appuntamentiotorino/" + dati.id);
        update(utenteRef, dati).then(() => {
          setLoading(false);
          onClose()
          Swal.fire({
            icon: 'success',
            title: 'Esitato correttamente ',
            showConfirmButton: false,
            timer: 1500
          })
        })
          .catch((error) => {
            setLoading(false);
            onClose()
            Swal.fire({
              icon: 'error',
              title: 'Esitazione fallita',
              showConfirmButton: false,
              timer: 1500
            })

          })







      }
      else {

      }
    })

  }

  return (
    <LoadingOverlay
      active={loading}
      spinner
    >
      <div className={styles.popupdata}>
        <div className={styles.modificaDati}>Esita</div>

        <div className={styles.inputsection}>
          <div style={{ flexDirection: "column" }}>

            <h3 className={styles.testo}>Nome</h3>
            <input
              className={styles.inputsectionChild}
              key="Nome"
              defaultValue={Persona.row.Nome || ''}
              type="text"
              placeholder="Nome"
              name="Nome"
              readOnly
              onChange={handleChange}
            />
          </div>
          <div style={{ flexDirection: "column" }}>

            <h3 className={styles.testo}>Telefono</h3>
            <input
              className={styles.inputsectionChild}
              key="Telefono"
              defaultValue={Persona.row.Telefono || ''}
              type="text"
              placeholder="Telefono"
              name="Telefono"
              readOnly
              onChange={handleChange}
            />
          </div>
        </div>




       
        <div className={styles.inputsectionWrapper}>
          <div style={{ flexDirection: "column" }}>
            <h3 className={styles.testo}>Esito </h3>
            <textarea
              className={styles.frameTextarea}
              placeholder="Esito"
              name='Esito'
              onChange={handleChange}
              defaultValue={Persona.row.Esito || ''}
            />
          </div>
        </div>

  








        <div className={styles.frameParent}>
          {
           
              <>
                <button className={styles.textWrapper} onClick={onClose}>
                  <h4 className={styles.text}>Annulla</h4>
                </button>
                <button className={styles.textContainer} onClick={() => updatedata()}>
                  <h4 className={styles.text}>Conferma</h4>
                </button>
              </>
              
           
          }
        </div>
      </div>
    </LoadingOverlay>
  );
};

export default PopUpData;
