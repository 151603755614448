import { useEffect, useState } from "react";
import { doc, updateDoc } from "firebase/firestore";
import LoadingOverlay from "react-loading-overlay";
import DatePicker, { registerLocale } from "react-datepicker";

import it from "date-fns/locale/it"; // the locale you want
registerLocale("it", it);
import Swal from "sweetalert2";

import { v4 } from "uuid";
import { ref, set, child, get, update, remove } from "firebase/database";

import { Rdb } from "../fire";
import "react-datepicker/dist/react-datepicker.css";

import styles from "./PopUpData.module.css";

const PopUpData = ({ onClose, datiAttività, edit }) => {
  const jsonData = require("../poolData/comuni.json");

  delete datiAttività?.row?.actions;

  const [dati, setDati] = useState(datiAttività.row);
  const [Citta, setCitta] = useState(datiAttività.row.Citta);
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    const value = event.target.value;

    setDati({ ...dati, [event.target.name]: value });

    if (event.target.name == "Cap") {
      const result = jsonData.find((item) =>
        item.cap.includes(event.target.value)
      );
      if (result) {
        const nome = result.nome;
        const nomeProvincia = result.sigla;
        console.log(nome);
        setCitta(nome + " (" + nomeProvincia + ")");
      } else {
        setCitta("");
      }
    }
  };

  function updatedata() {
    Swal.fire({
      title: "Vuoi modificare questa riga?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Conferma",
      confirmButtonColor: "#28b0b0",
      denyButtonText: `No`,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        if (dati.StatoEsito == "ordinato") {
          const utenteRef = ref(Rdb, "utenti/" + dati.id);
          let data = {
            ...dati,
            dataOrdinato: new Date(),
            StatoEsito: "ordinato",
          };
          update(utenteRef, data).then(() => {});
        } else if (dati.StatoEsito == "fatturato") {
          const utenteRef = ref(Rdb, "utenti/" + dati.id);
          let data = {
            ...dati,
            dataFatturato: new Date(),
            StatoEsito: "fatturato",
          };
          update(utenteRef, data).then(() => {});
        } else if (dati.StatoEsito == "incassato") {
          const utenteRef = ref(Rdb, "utenti/" + dati.id);
          let data = {
            ...dati,
            dataIncassato: new Date(),
            StatoEsito: "incassato",
          };
          update(utenteRef, data).then(() => {});
        }

        if (dati.Visto != "fissato") {
          setLoading(true);
          const utenteRef = ref(Rdb, "utenti/" + dati.id);
          //add ultima modifica to utente
          let data = {
            ...dati,
            ultimaModifica: new Date(),
            Citta: null,
          };
          update(utenteRef, data)
            .then(() => {
              setLoading(false);
              onClose();
            })
            .catch((error) => {
              setLoading(false);
              onClose();
            });
        } else if (dati.Visto == "fissato" && dati.Appuntamento != null) {
          setLoading(true);
          const utenteRef = ref(Rdb, "utenti/" + dati.id);
          let data;
          if (
            dati.pagamento == datiAttività.row.pagamento &&
            dati.pagamento2 == datiAttività.row.pagamento2
          ) {
            data = {
              ...dati,
              ultimaModifica: new Date(),
              Citta: null,
            };
          } else {
            data = {
              ...dati,
              ultimaModifica: new Date(),
              Citta: null,
              dataPagamento: new Date(),
            };
          }
          update(utenteRef, data)
            .then(() => {
              setLoading(false);
              onClose();
            })
            .catch((error) => {
              setLoading(false);
              onClose();
            });
        } else {
          Swal.fire({
            icon: "error",
            title: "Errore",
            text: "Inserisci una data valida",
          });
        }
      } else {
      }
    });
  }

  return (
    <LoadingOverlay active={loading} spinner>
      <div className={styles.popupdata}>
        <div className={styles.modificaDati}>
          {edit ? "Modifica dati" : "Visualizza dati"}
        </div>

        <div className={styles.inputsection}>
          <div style={{ flexDirection: "column" }}>
            <h3 className={styles.testo}>Nome</h3>
            <input
              className={styles.inputsectionChild}
              key="Nome"
              defaultValue={datiAttività.row.Nome || ""}
              type="text"
              placeholder="Nome"
              name="Nome"
              readOnly={!edit}
              onChange={handleChange}
            />
          </div>
          <div style={{ flexDirection: "column" }}>
            <h3 className={styles.testo}>Telefono</h3>
            <input
              className={styles.inputsectionChild}
              key="Telefono"
              defaultValue={datiAttività.row.Telefono || ""}
              type="text"
              placeholder="Telefono"
              name="Telefono"
              readOnly={!edit}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className={styles.inputsection}>
          <div style={{ flexDirection: "column" }}>
            <h3 className={styles.testo}>Età</h3>
            <input
              className={styles.inputsectionChild}
              key="eta"
              defaultValue={datiAttività.row.eta || ""}
              type="text"
              placeholder="Età"
              name="eta"
              readOnly={!edit}
              onChange={handleChange}
            />
          </div>
          <div style={{ flexDirection: "column" }}>
            <h3 className={styles.testo}>Stato Lead</h3>
            <select
              disabled={!edit}
              name="Visto"
              id="Visto"
              className={styles.inputsectionChild}
              defaultValue={dati.Visto}
              onChange={handleChange}
            >
              <option value={false}>In Attesa</option>
              <option value="lavorazione">In Lavorazione</option>
              <option value="rifiutato">Rifiutato</option>
              <option value="richiamare">Da Richiamare</option>
              <option value="nonrisponde">Non Risponde</option>
              <option value="numeroerrato">Numero Errato</option>
              <option value="fissato">App.Fissato</option>
              <option value="finanziaria">Esito Finanziaria</option>
              <option value="esita">Esita</option>
            </select>
          </div>
        </div>
        <div className={styles.inputsection}>
          <div style={{ flexDirection: "column" }}>
            <h3 className={styles.testo}>CAP</h3>
            <input
              className={styles.inputsectionChild}
              defaultValue={datiAttività.row.Cap || ""}
              type="text"
              placeholder="CAP"
              name="Cap"
              readOnly={!edit}
              onChange={handleChange}
              key="Cap"
            />
          </div>
          <div style={{ flexDirection: "column" }}>
            <h3 className={styles.testo}>Città</h3>
            <input
              className={styles.inputsectionChild}
              key="Citta"
              value={Citta || ""}
              type="text"
              name="Citta"
              readOnly
            />
          </div>
        </div>
        {dati.Visto == "fissato" ? (
          <div className={styles.inputsection}>
            <div style={{ flexDirection: "column" }}>
              <h3 className={styles.testo}>Data</h3>
              <DatePicker
                locale="it"
                className={styles.inputsectionChild}
                selected={
                  dati.Appuntamento ? new Date(dati?.Appuntamento) : null
                }
                showTimeSelect
                dateFormat={"dd/MM/yy HH:mm"}
                onChange={(date) =>
                  setDati({
                    ...dati,
                    Appuntamento: date,
                  })
                }
              />
            </div>
          </div>
        ) : null}

        <div className={styles.inputsectionWrapper}>
          <div style={{ flexDirection: "column" }}>
            <h3 className={styles.testo}>Note </h3>
            <textarea
              className={styles.frameTextarea}
              placeholder="Note"
              name="note"
              onChange={handleChange}
              readOnly={!edit}
              defaultValue={datiAttività.row.note || ""}
            />
          </div>
        </div>

        {edit == false ? (
          <>
            <div className={styles.modificaDati}>Visualizza esito test</div>

            <div className={styles.inputsection}>
              <div style={{ flexDirection: "column" }}>
                <h3 className={styles.testo}>Esito prime tre domande</h3>
                <input
                  className={styles.inputsectionChild}
                  key="Domande"
                  defaultValue={
                    "1." +
                      datiAttività.row.d1 +
                      " 2." +
                      datiAttività.row.d2 +
                      " 3." +
                      datiAttività.row.d3 || ""
                  }
                  type="text"
                  readOnly={true}
                  style={{ width: "15vw" }}
                />
              </div>
              <div style={{ flexDirection: "column" }}>
                <h3 className={styles.testo}>Punteggio test vocale</h3>
                <input
                  className={styles.inputsectionChild}
                  key="Punteggio"
                  defaultValue={datiAttività.row.Punti}
                  type="text"
                  readOnly={true}
                  style={{ width: "5vw" }}
                />
              </div>
            </div>

            <div className={styles.inputsection}>
              <div style={{ flexDirection: "column" }}>
                <h3 className={styles.testo}>500HZ</h3>
                <input
                  className={styles.inputsectionChild}
                  key="Punteggio"
                  defaultValue={datiAttività.row.hz500 || ""}
                  type="text"
                  readOnly={true}
                  style={{ width: "5vw" }}
                />
              </div>
              <div style={{ flexDirection: "column" }}>
                <h3 className={styles.testo}>1000HZ</h3>
                <input
                  className={styles.inputsectionChild}
                  key="Punteggio"
                  defaultValue={datiAttività.row.hz1000 || ""}
                  type="text"
                  readOnly={true}
                  style={{ width: "5vw" }}
                />
              </div>
              <div style={{ flexDirection: "column" }}>
                <h3 className={styles.testo}>4000HZ</h3>
                <input
                  className={styles.inputsectionChild}
                  key="Punteggio"
                  defaultValue={datiAttività.row.hz4000 || ""}
                  type="text"
                  readOnly={true}
                  style={{ width: "5vw" }}
                />
              </div>
            </div>
          </>
        ) : null}

        {dati.Visto == "esita" ? (
          <>
            <div className={styles.modificaDati}>Esita appuntamento </div>

            <div style={{ display: "flex", flexDirection: "row" }}>
              <input
                type="radio"
                id="positivo"
                name="tipoEsito"
                value="positivo"
                checked={dati.tipoEsito === "positivo"}
                onChange={handleChange}
              />
              <label
                style={{
                  fontSize: "18px",
                  marginRight: "5px",
                  fontWeight: "400",
                }}
                htmlFor="tipoEsito"
              >
                Positivo
              </label>
              <br />
              <input
                type="radio"
                id="negativo"
                name="tipoEsito"
                value="negativo"
                checked={dati.tipoEsito === "negativo"}
                onChange={handleChange}
              />
              <label
                style={{
                  fontSize: "18px",
                  marginRight: "5px",
                  fontWeight: "400",
                }}
                htmlFor="negativo"
              >
                Negativo
              </label>
              <br />
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <input
                type="radio"
                id="normudente"
                name="tipoEsito"
                value="normudente"
                checked={dati.tipoEsito === "normudente"}
                onChange={handleChange}
              />
              <label
                style={{
                  fontSize: "18px",
                  marginRight: "5px",
                  fontWeight: "400",
                }}
                htmlFor="normudente"
              >
                Normudente
              </label>
              <br />
              <input
                type="radio"
                id="assente"
                name="tipoEsito"
                value="assente"
                checked={dati.tipoEsito === "assente"}
                onChange={handleChange}
              />
              <label
                style={{
                  fontSize: "18px",
                  marginRight: "5px",
                  fontWeight: "400",
                }}
                htmlFor="assente"
              >
                Assente
              </label>
              <br />
            </div>

            <div className={styles.inputsectionWrapper}>
              <div style={{ flexDirection: "column" }}>
                <h3 className={styles.testo}>Note esito </h3>
                <textarea
                  className={styles.frameTextarea}
                  placeholder="Note esito"
                  name="noteEsito"
                  onChange={handleChange}
                  defaultValue={datiAttività.row.noteEsito || ""}
                />
              </div>
            </div>

            {dati.tipoEsito == "positivo" ? (
              <>
                <div className={styles.inputsection}>
                  <div style={{ flexDirection: "column" }}>
                    <h3 className={styles.testo}>Stato esito</h3>
                    <select
                      disabled={!edit}
                      name="StatoEsito"
                      id="StatoEsito"
                      className={styles.inputsectionChild}
                      defaultValue={dati.StatoEsito}
                      onChange={handleChange}
                    >
                      <option value={false}></option>
                      <option value="ordinato">Ordinato</option>
                      <option value="fatturato">Fatturato</option>
                      <option value="incassato">Incassato</option>
                    </select>
                  </div>
                </div>
                <div className={styles.inputsection}>
                  <div style={{ flexDirection: "column" }}>
                    <h3 className={styles.testo}>Tipo pagamento</h3>
                    <select
                      disabled={!edit}
                      name="tipoPagamento"
                      id="tipoPagamento"
                      className={styles.inputsectionChild}
                      defaultValue={dati.tipoPagamento}
                      onChange={handleChange}
                    >
                      <option value="Contanti">Contanti</option>
                      <option value="Finaziamento">Finaziamento</option>
                      <option value="Interno">Interno</option>
                      <option value="Misto">Misto</option>
                      <option value="Noleggio">Noleggio</option>
                      <option value="asl">Partica ASL</option>
                    </select>
                  </div>
                  <div style={{ flexDirection: "column" }}>
                    <h3 className={styles.testo}>Valore</h3>
                    <input
                      className={styles.inputsectionChild}
                      key="pagamento"
                      defaultValue={datiAttività.row.pagamento || ""}
                      type="text"
                      placeholder="valore"
                      name="pagamento"
                      readOnly={!edit}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className={styles.inputsection}>
                  <div style={{ flexDirection: "column" }}>
                    <h3 className={styles.testo}>Tipo pagamento 2</h3>
                    <select
                      disabled={!edit}
                      name="tipoPagamento2"
                      id="tipoPagamento2"
                      className={styles.inputsectionChild}
                      defaultValue={dati.tipoPagamento2}
                      onChange={handleChange}
                    >
                      <option value="Nessuno">Nessuno</option>
                      <option value="Contanti">Contanti</option>
                      <option value="Finaziamento">Finaziamento</option>
                      <option value="Interno">Interno</option>
                      <option value="Misto">Misto</option>
                      <option value="Noleggio">Noleggio</option>
                      <option value="asl">Partica ASL</option>
                    </select>
                  </div>
                  <div style={{ flexDirection: "column" }}>
                    <h3 className={styles.testo}>Valore</h3>
                    <input
                      className={styles.inputsectionChild}
                      key="pagamento2"
                      defaultValue={datiAttività.row.pagamento2 || ""}
                      type="text"
                      placeholder="valore"
                      name="pagamento2"
                      readOnly={!edit}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </>
            ) : null}
          </>
        ) : null}

        <div className={styles.frameParent}>
          {edit ? (
            <>
              <button className={styles.textWrapper} onClick={onClose}>
                <h4 className={styles.text}>Annulla</h4>
              </button>
              <button
                className={styles.textContainer}
                onClick={() => updatedata()}
              >
                <h4 className={styles.text}>Conferma</h4>
              </button>
            </>
          ) : (
            <button className={styles.textWrapper} onClick={onClose}>
              <h4 className={styles.text}>Chiudi</h4>
            </button>
          )}
        </div>
      </div>
    </LoadingOverlay>
  );
};

export default PopUpData;
