import { useState, useCallback, useEffect, useContext } from "react";
import Sidebar from "../components/KingLeadSidebar2";
import PortalDrawer from "../components/PortalDrawer";
import KingLeadSidebar from "../components/KingLeadSidebar";
import { AiTwotoneEdit } from 'react-icons/ai';
import { MdRemoveCircle } from 'react-icons/md';
import { MdWorkOutline } from 'react-icons/md';

import LeadMenu from "../components/LeadMenu";
import PortalPopup from "../components/PortalPopup2";
import Appuntamento from "../components/otorino/Appuntamento";

import Esita from "../components/otorino/Esita";

import { auth, Rdb } from "../fire";
import { ref, set, child, get, update, remove } from "firebase/database";

import { DataGrid, itIT, GridActionsCell, GridActionsCellItem } from '@mui/x-data-grid';
import { db } from "../fire";

import user from '../contexts/auth';

import styles from "./Home.module.css";

const Otorino = () => {
  const [isSidebar1Open, setSidebar1Open] = useState(false);
  const [isPopUpDataOpen, setPopUpDataOpen] = useState(false);
  const [isPopUpOtorino, setPopUpOtorino] = useState(false);

  const [edit, setEdit] = useState(true);

  const [value, setValue] = useState([]);

  const userData = useContext(user)
  const [lead, setLead] = useState({
    totali: 0,
    appuntamento: 0,
    test: 0,
    otorino: 0
  })
  let rows = [];


  const [loading, setLoading] = useState(false);
  const [openVisualizza, setOpenVisualizza] = useState(false);
  const [openConcludi, setOpenConcludi] = useState(false);


  const [taskSpecifica, setTaskSpecifica] = useState({});

  const [task, setTask] = useState([]);
  const taskColonne = [
    { field: 'id', headerName: 'ID', width: 150, hide: true },
    {
      field: "actions",
      type: "actions",
      width: 40,
      getActions: (cell) => [
        <GridActionsCellItem
          label={"Esita"}
          icon={<AiTwotoneEdit fontSize={"1.2em"} />}
          onClick={() => openModifica(cell)}
          showInMenu
        />,
       
      ],


    },
    { field: 'Nome', headerName: 'Nome', width: 250 },
    { field: 'Telefono', headerName: 'Telefono', width: 150 },




    {
      field: 'Data', headerName: 'Data Appuntamento', width: 200, renderCell: (cell) => {
        const data = new Date(cell.row?.Data)
        const minuti = data.getMinutes() < 10 ? "0" + data.getMinutes() : data.getMinutes()
        if (data.getDate()) {
          return <p>{data.getDate() + "/" + (data.getMonth() + 1) + "/" + data.getFullYear() + " " + data.getHours() + ":" + minuti}</p>
        }
      }
    },
    {
      field: 'Stato', headerName: 'Stato', width: 150,
      renderCell: (cell) => {

        if (cell.row?.Esitato == false) {
          return <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignContent: "center", alignItems: "center", backgroundColor: "#D1D100", borderRadius: 20, width: 130, height: 30 }}>
            <MdRemoveCircle color="white" fontSize="1.3em" />
            <h4 style={{ color: "white", marginLeft: 10 }}>In Attesa</h4>
          </div>
        }
        else if (cell.row?.Esitato == true) {
          return <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignContent: "center", alignItems: "center", backgroundColor: "green", borderRadius: 20, width: 130, height: 30 }}>
            < MdWorkOutline color="white" fontSize="1.3em" />
            <h4 style={{ color: "white", marginLeft: 10 }}>Esitato</h4>
          </div>
        }



      }
    },

    // {
    //   field: 'Data', headerName: 'Data', width: 150, renderCell: (cell) => {
    //     let date = cell.row.Data.toDate();
    //     return <h4>{date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()}</h4>
    //   }
    // },

  ]

  if (userData.Tipo != "otorino") {
    taskColonne.push(
      { field: 'Otorino', headerName: 'Otorino', width: 150 }
    )
  }



  function openModifica(value) {
    if (userData.Tipo == "admin" || userData.Tipo == "amministrazione") {
      setEdit(true)
      setValue(value);
      setPopUpDataOpen(true);

    }
    else {
      Toast.fire({
        icon: 'error',
        title: 'Non hai i permessi per fare ciò',
        text: 'Richiedi una modifica via email'
      })
    }


  }
  function openView(value) {
    setEdit(false)
    setValue(value);
    setPopUpDataOpen(true);



  }

  function openOtorino(value) {
    setValue(value);
    setPopUpOtorino(true);
  }

  const closePopUpData = useCallback(() => {
    getData();
    setPopUpDataOpen(false);
  }, []);


  const closePopUpOtorini = useCallback(() => {
    setPopUpOtorino(false);
  }, []);



  function concludiTask() {
    setOpenVisualizza(false);
    setOpenConcludi(true)

  }




  const openSidebar1 = useCallback(() => {
    setSidebar1Open(true);
  }, []);

  const closeSidebar1 = useCallback(() => {
    setSidebar1Open(false);
  }, []);
  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add(styles.animate);
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);




  function ricaricaDati() {
    setOpenConcludi(false)
    getData()
  }



  function getData() {
    //get appuntamentiotorino and filter by otorino id
    get((ref(Rdb, 'appuntamentiotorino'))).then((snapshot) => {
      if (snapshot.exists()) {
        let data = snapshot.val();

        let array = Object.keys(data).map((key) => {


          return { ...data[key], id: key }
        })
        let array2 = array.filter((item) => {
          return item.OtorinoId == userData.id
        })
        if (userData.Tipo == "otorino") {
          setTask(array2)
        }
        else {
          setTask(array)
        }
      } else {
        console.log("No data available");
      }
    }
    ).catch((error) => {
      console.error(error);
    }
    );

  }


  useEffect(() => {
    getData()
  }, []);

  return (
    <>
      <div className={styles.home} data-animate-on-scroll>
        <KingLeadSidebar attivo="Otorini" />
        <div className={styles.mainui}>
          <LeadMenu leadOverviewText="Otorini" openSidebar1={openSidebar1} />
          <div className={styles.main}>
            <h3 className={styles.analisiLead}>Analisi Appuntamenti</h3>
            <div className={styles.frameParent}>
              <div className={styles.totaliParent}>
                <h4 className={styles.totali}>Totali</h4>
                <div className={styles.icons8Ventiquattrore901Parent}>
                  <img
                    className={styles.icons8Ventiquattrore901}
                    alt=""
                    src="../work.svg" />
                  <h3 className={styles.analisiLead}>{task.length}</h3>
                </div>
              </div>
              <div className={styles.confermateParent}>
                <h4 className={styles.totali}>Esitati</h4>
                <div className={styles.icons8Ventiquattrore901Group}>
                  <img
                    className={styles.icons8Ventiquattrore901}
                    alt=""
                    src="../test.svg"
                  />
                  <h3 className={styles.analisiLead}>{
                    //get esitati and filter by otorino id 
                    task.filter((item) => {
                      return item.Esitato == true
                    }
                    ).length
                  }</h3>
                </div>
              </div>
              <div className={styles.confermateParent}>
                <h4 className={styles.totali}>Da esitare</h4>
                <div className={styles.icons8Ventiquattrore901Container}>
                  <img
                    className={styles.icons8Ventiquattrore901}
                    alt=""
                    src="../ear.svg" />
                  <h3 className={styles.analisiLead}>{
                    task.filter((item) => {
                      return item.Esitato == false
                    }
                    ).length}</h3>
                </div>
              </div>

            </div>





            <h3 className={styles.analisiLead}>I tuoi appuntamenti</h3>


            <DataGrid
              rows={task}
              columns={taskColonne}
              pageSize={100}
              rowsPerPageOptions={[5]}
              localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
              sx={{ width: "100%", backgroundColor: "white", borderRadius: 4, minHeight: "80vh" }}
              loading={loading}
              rowHeight={50}


            />

          </div>
        </div>
      </div>
      {isSidebar1Open && (
        <PortalDrawer
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Left"
          onOutsideClick={closeSidebar1}
        >
          <Sidebar attivo="Overview" />
        </PortalDrawer>
      )}
      {isPopUpDataOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closePopUpData}
        >

          <Esita onClose={closePopUpData} Persona={value} />


        </PortalPopup>
      )}
      {isPopUpOtorino && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closePopUpOtorini}
        >

          <Appuntamento onClose={closePopUpOtorini} Persona={value} />


        </PortalPopup>
      )}
    </>
  );
};

export default Otorino;
