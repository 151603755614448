import { useState, useCallback, useEffect, useContext } from "react";
import Sidebar from "../components/KingLeadSidebar2";
import PortalDrawer from "../components/PortalDrawer";
import KingLeadSidebar from "../components/KingLeadSidebar";
import { AiTwotoneEdit } from "react-icons/ai";
import { MdRemoveCircle } from "react-icons/md";
import { MdWorkOutline } from "react-icons/md";
import { BiShow } from "react-icons/bi";
import { GiPlagueDoctorProfile } from "react-icons/gi";
import { MdDelete } from "react-icons/md";

import { GiBowman } from "react-icons/gi";
import Swal from "sweetalert2";

import LeadMenu from "../components/LeadMenu";
import PortalPopup from "../components/PortalPopup2";
import Appuntamento from "../components/otorino/Appuntamento";
import NuovoLead from "../components/lead/nuovoLead";
import AssegnaLead from "../components/lead/AssegnaLead";

import PopUpData from "../components/PopUpData";

import { auth, Rdb } from "../fire";
import { ref, set, child, get, update, remove } from "firebase/database";
import DatePicker, { registerLocale } from "react-datepicker";

import it from "date-fns/locale/it"; // the locale you want
registerLocale("it", it);
import {
  DataGrid,
  itIT,
  GridActionsCell,
  GridActionsCellItem,
} from "@mui/x-data-grid";
import { db } from "../fire";

import user from "../contexts/auth";

import styles from "./Home.module.css";

const Home = () => {
  const [isSidebar1Open, setSidebar1Open] = useState(false);
  const [isPopUpDataOpen, setPopUpDataOpen] = useState(false);
  const [isPopUpOtorino, setPopUpOtorino] = useState(false);
  const [isPopUpLead, setNuovoutente] = useState(false);
  const [isAssegnaLeadOpen, setAssegnaLeadOpen] = useState(false);
  const [edit, setEdit] = useState(true);

  const jsonData = require("../poolData/comuni.json");

  const [value, setValue] = useState([]);

  const userData = useContext(user);
  const [lead, setLead] = useState({
    totali: 0,
    appuntamento: 0,
    test: 0,
    otorino: 0,
  });
  let rows = [];

  const [loading, setLoading] = useState(false);
  const [openVisualizza, setOpenVisualizza] = useState(false);
  const [openConcludi, setOpenConcludi] = useState(false);

  const [taskSpecifica, setTaskSpecifica] = useState({});

  const [task, setTask] = useState([]);
  const taskColonne = [
    { field: "id", headerName: "ID", width: 150, hide: true },
    {
      field: "actions",
      type: "actions",
      width: 40,
      getActions: (cell) => [
        <GridActionsCellItem
          label={"Modifica"}
          icon={<AiTwotoneEdit fontSize={"1.2em"} />}
          onClick={() => openModifica(cell)}
          showInMenu
        />,

        <GridActionsCellItem
          label={"Mostra Di Più"}
          icon={<BiShow fontSize={"1.2em"} />}
          onClick={() => openView(cell)}
          showInMenu
        />,
        <GridActionsCellItem
          label={"Visita Otorino"}
          icon={<GiPlagueDoctorProfile fontSize={"1.2em"} />}
          onClick={() => openOtorino(cell)}
          showInMenu
        />,
        <GridActionsCellItem
          label={"Assegna Lead"}
          icon={<GiBowman fontSize={"1.2em"} />}
          onClick={() => openPartner(cell)}
          showInMenu
        />,

        <GridActionsCellItem
          label={"Elimina"}
          icon={<MdDelete fontSize={"1.2em"} />}
          onClick={() => elimina(cell)}
          showInMenu
        />,
      ],
    },
    { field: "Nome", headerName: "Nome", width: 220 },
    { field: "Telefono", headerName: "Telefono", width: 150 },
    { field: "eta", headerName: "Età", width: 60 },
    {
      field: "Data",
      headerName: "Data",
      width: 100,
      renderCell: (cell) => {
        const data = cell.row?.Data;
        if (data?.getDate()) {
          return (
            <p>
              {data.getDate() +
                "/" +
                (data.getMonth() + 1) +
                "/" +
                data.getFullYear()}
            </p>
          );
        }
      },
    },
    {
      field: "Visto",
      headerName: "Stato",
      width: 150,
      renderCell: (cell) => {
        if (cell.row?.Visto == false || cell.row?.Visto == "false") {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                backgroundColor: "#D1D100",
                borderRadius: 20,
                width: 130,
                height: 30,
              }}
            >
              <MdRemoveCircle color="white" fontSize="1.3em" />
              <h4 style={{ color: "white", marginLeft: 10 }}>In Attesa</h4>
            </div>
          );
        } else if (cell.row?.Visto == "lavorazione") {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                backgroundColor: "orange",
                borderRadius: 20,
                width: 130,
                height: 30,
              }}
            >
              <MdWorkOutline color="white" fontSize="1.3em" />
              <h4 style={{ color: "white", marginLeft: 10 }}>In Lavorazione</h4>
            </div>
          );
        } else if (cell.row?.Visto == "rifiutato") {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                backgroundColor: "red",
                borderRadius: 20,
                width: 130,
                height: 30,
              }}
            >
              <MdRemoveCircle color="white" fontSize="1.3em" />
              <h4 style={{ color: "white", marginLeft: 10 }}>Rifiutato</h4>
            </div>
          );
        } else if (cell.row?.Visto == "richiamare") {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                backgroundColor: "#3002fa",
                borderRadius: 20,
                width: 130,
                height: 30,
              }}
            >
              <MdRemoveCircle color="white" fontSize="1.3em" />
              <h4 style={{ color: "white", marginLeft: 10 }}>Da Richiamare</h4>
            </div>
          );
        } else if (cell.row?.Visto == "nonrisponde") {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                backgroundColor: "#fa5102",
                borderRadius: 20,
                width: 130,
                height: 30,
              }}
            >
              <MdRemoveCircle color="white" fontSize="1.3em" />
              <h4 style={{ color: "white", marginLeft: 10 }}>Non Risponde</h4>
            </div>
          );
        } else if (cell.row?.Visto == "numeroerrato") {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                backgroundColor: "#f56042",
                borderRadius: 20,
                width: 130,
                height: 30,
              }}
            >
              <MdRemoveCircle color="white" fontSize="1.3em" />
              <h4 style={{ color: "white", marginLeft: 10 }}>Numero errato</h4>
            </div>
          );
        } else if (cell.row?.Visto == "fissato") {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                backgroundColor: "green",
                borderRadius: 20,
                width: 130,
                height: 30,
              }}
            >
              <MdWorkOutline color="white" fontSize="1.3em" />
              <h4 style={{ color: "white", marginLeft: 10 }}>App. fissato</h4>
            </div>
          );
        } else if (cell.row?.Visto == "finanziaria") {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                backgroundColor: "green",
                borderRadius: 20,
                width: 130,
                height: 30,
              }}
            >
              <MdWorkOutline color="white" fontSize="1.3em" />
              <h4 style={{ color: "white", marginLeft: 10 }}>
                EsitoFinanziaria
              </h4>
            </div>
          );
        } else if (cell.row?.Visto == "esita") {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                backgroundColor: "green",
                borderRadius: 20,
                width: 130,
                height: 30,
              }}
            >
              <MdWorkOutline color="white" fontSize="1.3em" />
              <h4 style={{ color: "white", marginLeft: 10 }}>Esitato</h4>
            </div>
          );
        }
      },
    },
    {
      field: "StatoEsito",
      headerName: "Stato Esito",
      width: 150,
      renderCell: (cell) => {
        if (cell.row?.StatoEsito == "ordinato") {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                backgroundColor: "#D1D100",
                borderRadius: 20,
                width: 130,
                height: 30,
              }}
            >
              <MdRemoveCircle color="white" fontSize="1.3em" />
              <h4 style={{ color: "white", marginLeft: 10 }}>Ordinato</h4>
            </div>
          );
        } else if (cell.row?.StatoEsito == "fatturato") {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                backgroundColor: "#D1D100",
                borderRadius: 20,
                width: 130,
                height: 30,
              }}
            >
              <MdRemoveCircle color="white" fontSize="1.3em" />
              <h4 style={{ color: "white", marginLeft: 10 }}>Fatturato</h4>
            </div>
          );
        } else if (cell.row?.StatoEsito == "incassato") {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                backgroundColor: "#D1D100",
                borderRadius: 20,
                width: 130,
                height: 30,
              }}
            >
              <MdRemoveCircle color="white" fontSize="1.3em" />
              <h4 style={{ color: "white", marginLeft: 10 }}>Incassato</h4>
            </div>
          );
        }
      },
    },

    { field: "PartnerName", headerName: "Partner", width: 150 },
    {
      field: "PartnerData",
      headerName: "Assegnazione Partner",
      width: 200,
      renderCell: (cell) => {
        const data = cell.row?.PartnerData;
        if (data?.getDate()) {
          return (
            <p>
              {data.getDate() +
                "/" +
                (data.getMonth() + 1) +
                "/" +
                data.getFullYear()}
            </p>
          );
        }
      },
    },
    { field: "Percentuale", headerName: "Percentuale", width: 100 },

    { field: "Cap", headerName: "Cap", width: 100 },
    {
      field: "Citta",
      headerName: "Città",
      width: 200,
    },
    { field: "Regione", headerName: "Regione", width: 100 },

    {
      field: "ultimaModifica",
      headerName: "Ultima Modifica",
      width: 120,
      renderCell: (cell) => {
        const data = cell.row?.ultimaModifica;
        if (data?.getDate()) {
          return (
            <p>
              {data.getDate() +
                "/" +
                (data.getMonth() + 1) +
                "/" +
                data.getFullYear()}
            </p>
          );
        }
      },
    },
    {
      field: "tipo",
      headerName: "Tipo",
      width: 200,
      renderCell: (cell) => {
        const tipo = cell.row?.tipo;
        if (!tipo) return <p>Lead FB automatico</p>;
      },
    },

    // {
    //   field: 'Data', headerName: 'Data', width: 150, renderCell: (cell) => {
    //     let date = cell.row.Data.toDate();
    //     return <h4>{date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()}</h4>
    //   }
    // },
  ];

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  function openModifica(value) {
    if (userData.Tipo == "admin" || userData.Tipo == "amministrazione") {
      setEdit(true);
      setValue(value);
      setPopUpDataOpen(true);
    } else {
      Toast.fire({
        icon: "error",
        title: "Non hai i permessi per fare ciò",
        text: "Richiedi una modifica via email",
      });
    }
  }
  function openPartner(value) {
    setValue(value);
    setAssegnaLeadOpen(true);
  }
  function openView(value) {
    setEdit(false);
    setValue(value);
    setPopUpDataOpen(true);
  }

  function openOtorino(value) {
    setValue(value);
    setPopUpOtorino(true);
  }

  const closePopUpData = useCallback(() => {
    getData();
    setPopUpDataOpen(false);
  }, []);

  const closePopUpOtorini = useCallback(() => {
    setPopUpOtorino(false);
  }, []);

  const closePopUpLead = useCallback(() => {
    setNuovoutente(false);
  }, []);

  function concludiTask() {
    setOpenVisualizza(false);
    setOpenConcludi(true);
  }

  function newLeadCreated() {
    Toast.fire({
      icon: "success",
      title: "Lead creato con successo",
    });
    getData();
  }

  function elimina(value) {
    //delete lead from firebase
    Swal.fire({
      title: "Vuoi eliminare questo lead?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Conferma",
      confirmButtonColor: "#08174d",
      denyButtonText: `No`,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        remove(ref(Rdb, "utenti/" + value.row.id))
          .then(() => {
            Toast.fire({
              icon: "success",
              title: "Lead eliminato con successo",
            });
            getData();
          })
          .catch((error) => {
            Toast.fire({
              icon: "error",
              title: "Errore durante l'eliminazione del lead",
            });
            console.log(error);
          });
      }
    });
  }

  const openSidebar1 = useCallback(() => {
    setSidebar1Open(true);
  }, []);

  const closeSidebar1 = useCallback(() => {
    setSidebar1Open(false);
  }, []);
  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add(styles.animate);
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);

  function ricaricaDati() {
    setOpenConcludi(false);
    getData();
  }

  function convertiStringaNumerica(valore) {
    if (typeof valore === "string") {
      // Sostituisci la virgola con il punto e converti in numero
      // Funziona sia per numeri interi in forma di stringa ("700") che per decimali ("700,50")
      return parseFloat(valore.replace(",", "."));
    }
    return valore;
  }
  function getData() {
    rows = [];
    let test = 0;
    let otorino = 0;
    let app = 0;
    get(ref(Rdb, "account")).then((snapshot) => {
      Object.values(snapshot.val()).forEach((val) => {
        if (val.Tipo == "partner") {
          rows = [...rows, val];
        }
      });

      const utenti = Object.values(snapshot.val());

      get(ref(Rdb, "utenti")).then((snapshot) => {
        Object.entries(snapshot.val()).forEach((entry) => {
          const [key, value] = entry;
          console.log(value);
          if (
            value.Visto == "esita" &&
            value.tipoEsito == "positivo" &&
            value.Tipo !== "partner"
          ) {
            if (value.PartnerName) {
              const pagamento = convertiStringaNumerica(value?.pagamento) ?? 0;
              const pagamento2 =
                convertiStringaNumerica(value?.pagamento2) ?? 0;

              //find partner and calculate percentage with PartnerName in value and find in utenti with nome and cognome

              const PartnerName = value.PartnerName.trim()
                .toUpperCase()
                .replace(/\s/g, "");
              const percentualePartner = utenti.find((value) => {
                if (value.Tipo == "partner") {
                  const nomeCompleto = `${value?.Nome?.trim().replace(
                    /\s/g,
                    ""
                  )}${
                    value?.Cognome?.trim().replace(/\s/g, "") ?? ""
                  }`.toUpperCase();

                  return nomeCompleto === PartnerName;
                }
              });

              value.Percentuale =
                ((pagamento + pagamento2) / 100) *
                percentualePartner?.Percentuale;
            }
            if (value.Cap) {
              const result = jsonData.find((item) =>
                item.cap.includes(value.Cap)
              );

              if (result) {
                const nome = result.nome;
                const nomeProvincia = result.sigla;
                const regione = result.regione.nome;
                value.Citta = nome + " (" + nomeProvincia + ")";
                value.Regione = regione;
              }
            }
            if (value.Data) {
              value.Data = new Date(value.Data);
            }
            if (value.ultimaModifica) {
              value.ultimaModifica = new Date(value.ultimaModifica);
            }
            if (value.PartnerData) {
              value.PartnerData = new Date(value.PartnerData);
            }
            let val = {
              ...value,
              id: key,
            };

            rows = [...rows, val];

            if (value.tipo == "Otorino") {
              otorino++;
            } else if (value.tipo == "Appuntamento da test") {
              test++;
            } else {
              app++;
            }
          }
        });
        console.log(rows);
        let totale = app + test + otorino;
        setLead({
          totali: totale,
          appuntamento: app,
          test: test,
          otorino: otorino,
        });
        //check if in rows there are lead with Tipo==Partner and remove it
        rows = rows.filter((value) => value.Tipo !== "partner");
        setTask(rows);
      });
    });
  }

  useEffect(() => {
    getData();
  }, []);

  function getIncassato() {
    let totale = 0;
    task.forEach((value) => {
      // Converti la dataIncassato in un oggetto Date

      // Verifica se dataIncassato è nel mese e anno correnti
      if (!value.dataIncassato) return;
      const dataIncassato = new Date(value.dataIncassato);
      console.log(meseAnno);

      console.log(dataIncassato);
      if (
        dataIncassato.getMonth() === meseAnno.getMonth() &&
        dataIncassato.getFullYear() === meseAnno.getFullYear()
      ) {
        if (value.StatoEsito == "incassato") {
          totale += convertiStringaNumerica(value.pagamento) ?? 0;
          totale += convertiStringaNumerica(value.pagamento2) ?? 0;
        }
      }
    });
    return totale;
  }
  function getFatturato() {
    let totale = 0;
    task.forEach((value) => {
      // Converti la dataIncassato in un oggetto Date

      // Verifica se dataIncassato è nel mese e anno correnti
      if (!value.dataFatturato) return;
      const dataFatturato = new Date(value.dataFatturato);

      if (
        dataFatturato.getMonth() === dataFatturato.getMonth() &&
        dataFatturato.getFullYear() === dataFatturato.getFullYear()
      ) {
        if (value.StatoEsito == "fatturato") {
          totale += convertiStringaNumerica(value.pagamento) ?? 0;
          totale += convertiStringaNumerica(value.pagamento2) ?? 0;
        }
      }
    });
    return totale;
  }
  function getOrdinato() {
    let totale = 0;
    task.forEach((value) => {
      // Converti la dataIncassato in un oggetto Date

      // Verifica se dataIncassato è nel mese e anno correnti
      if (!value.dataOrdinato) return;
      const dataOrdinato = new Date(value.dataOrdinato);

      if (
        dataOrdinato.getMonth() === dataOrdinato.getMonth() &&
        dataOrdinato.getFullYear() === dataOrdinato.getFullYear()
      ) {
        if (value.StatoEsito == "ordinato") {
          totale += convertiStringaNumerica(value.pagamento) ?? 0;
          totale += convertiStringaNumerica(value.pagamento2) ?? 0;
        }
      }
    });
    return totale;
  }
  function getPercentuale() {
    let totale = 0;
    task.forEach((value) => {
      // Converti la dataIncassato in un oggetto Date

      // Verifica se dataIncassato è nel mese e anno correnti
      if (!value.dataIncassato) return;
      const dataIncassato = new Date(value.dataIncassato);
      console.log(meseAnno);

      console.log(dataIncassato);
      if (
        dataIncassato.getMonth() === meseAnno.getMonth() &&
        dataIncassato.getFullYear() === meseAnno.getFullYear()
      ) {
        if (value.Percentuale) {
          totale += value.Percentuale;
        }
      }
    });
    return totale.toFixed(2);
  }

  const [meseAnno, setMeseAnno] = useState(new Date());

  return (
    <>
      <div className={styles.home} data-animate-on-scroll>
        <KingLeadSidebar attivo="Analytics" />
        <div className={styles.mainui}>
          <LeadMenu leadOverviewText="Analytics" openSidebar1={openSidebar1} />
          <div className={styles.main}>
            <h3 className={styles.analisiLead}>Analytics</h3>
            <DatePicker
              locale="it"
              className={styles.inputsectionChild}
              selected={meseAnno}
              dateFormat="MM/yyyy"
              showMonthYearPicker
              onChange={(date) => setMeseAnno(date)}
            />
            <div className={styles.frameParent}>
              <div
                className={styles.confermateParent}
                style={{ paddingLeft: 0, paddingRight: 0 }}
              >
                <h4 className={styles.totali}>Incassato</h4>
                <div className={styles.icons8Ventiquattrore901Parent}>
                  <img
                    className={styles.icons8Ventiquattrore901}
                    alt=""
                    src="../m.svg"
                  />
                  <h3 className={styles.analisiLead}>{getIncassato()}€</h3>
                </div>
              </div>
              <div
                className={styles.confermateParent}
                style={{ paddingLeft: 0, paddingRight: 0 }}
              >
                <h4 className={styles.totali}>Fatturato</h4>
                <div className={styles.icons8Ventiquattrore901Group}>
                  <img
                    className={styles.icons8Ventiquattrore901}
                    alt=""
                    src="../i.svg"
                  />
                  <h3 className={styles.analisiLead}>{getFatturato()}€</h3>
                </div>
              </div>
              <div
                className={styles.confermateParent}
                style={{ paddingLeft: 0, paddingRight: 0 }}
              >
                <h4 className={styles.totali}>Ordinato</h4>
                <div className={styles.icons8Ventiquattrore901Container}>
                  <img
                    className={styles.icons8Ventiquattrore901}
                    alt=""
                    src="../o.svg"
                  />
                  <h3 className={styles.analisiLead}>{getOrdinato()}€</h3>
                </div>
              </div>
              <div
                className={styles.confermateParent}
                style={{ paddingLeft: 0, paddingRight: 0 }}
              >
                <h4 className={styles.totali}>Percentuali</h4>
                <div className={styles.frameDiv}>
                  <img
                    className={styles.icons8Ventiquattrore901}
                    alt=""
                    src="../p.svg"
                  />
                  <h3 className={styles.analisiLead}>{getPercentuale()}€</h3>
                </div>
              </div>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <h3 className={styles.analisiLead}>I tuoi clienti</h3>
            </div>

            <DataGrid
              rows={task}
              columns={taskColonne}
              pageSize={100}
              rowsPerPageOptions={[5]}
              localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
              sx={{
                width: "100%",
                backgroundColor: "white",
                borderRadius: 4,
                minHeight: "80vh",
              }}
              loading={loading}
              rowHeight={50}
              initialState={{
                sorting: {
                  sortModel: [{ field: "Data", sort: "desc" }],
                },
              }}
            />
          </div>
        </div>
      </div>
      {isSidebar1Open && (
        <PortalDrawer
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Left"
          onOutsideClick={closeSidebar1}
        >
          <Sidebar attivo="Overview" />
        </PortalDrawer>
      )}
      {isPopUpDataOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closePopUpData}
        >
          <PopUpData
            onClose={closePopUpData}
            datiAttività={value}
            edit={edit}
          />
        </PortalPopup>
      )}
      {isPopUpOtorino && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closePopUpOtorini}
        >
          <Appuntamento onClose={closePopUpOtorini} Persona={value} />
        </PortalPopup>
      )}
      {isPopUpLead && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closePopUpLead}
        >
          <NuovoLead onClose={closePopUpLead} onSuccess={newLeadCreated} />
        </PortalPopup>
      )}
      {isAssegnaLeadOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={() => setAssegnaLeadOpen(false)}
        >
          <AssegnaLead
            onClose={() => setAssegnaLeadOpen(false)}
            onSuccess={() => {
              Toast.fire({
                icon: "success",
                title: "Lead assegnato con successo",
              });
              getData();
            }}
            datiAttività={value}
          />
        </PortalPopup>
      )}
    </>
  );
};

export default Home;
