import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";
import { getDatabase } from "firebase/database";

import { getStorage } from "@firebase/storage";
import { getAuth } from "firebase/auth";


// Follow this pattern to import other Firebase services
// import { } from 'firebase/<service>';

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: "AIzaSyDgEoXkmXTUsU9I00muzGKvJ22op-3MKn0",
  authDomain: "dottorudito-99a3c.firebaseapp.com",
  databaseURL: "https://dottorudito-99a3c-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "dottorudito-99a3c",
  storageBucket: "dottorudito-99a3c.appspot.com",
  messagingSenderId: "840298230679",
  appId: "1:840298230679:web:590a32cc4beb2a0d1a0ad2",
  measurementId: "G-W1TG38W1EX"
};

const secondaryApp = initializeApp(firebaseConfig, "Secondary");


const app = initializeApp(firebaseConfig);
const storage = getStorage(app)
const db = getFirestore(app);
const Rdb = getDatabase();
const auth = getAuth(app);
const auth2 = getAuth(secondaryApp);


export { db, storage, auth,auth2,Rdb };