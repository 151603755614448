import { useState, useCallback } from "react";
import { auth } from "../fire";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import LoadingOverlay from 'react-loading-overlay';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'


import styles from "./Login.module.css";

const Login = () => {
  const [isActive, setActive] = useState(false);
  const navigate = useNavigate()
  const [dati, setDati] = useState({
    email: "",
    password: ""
  });
  const handleChange = (event) => {
    const value = event.target.value;
    setDati({ ...dati, [event.target.name]: value });

  };

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })


  function authFunction() {
    setActive(true);
    const auth = getAuth();
    signInWithEmailAndPassword(auth, dati.email, dati.password)
      .then((userCredential) => {
        // Signed in 
        setActive(false);
        const user = userCredential.user;
        navigate("/")
        // ...
      })
      .catch((error) => {
        setActive(false);
        Toast.fire({
          icon: 'error',
          title: "Errore nell'autenticazione",
          text:"codice errore: "+error.code+" Riprova o contatta il supporto "
        })

      });
  }



  return (
    <>
      <LoadingOverlay
        active={isActive}
        spinner
      >
        <div className={styles.login}>
          <div className={styles.king1Parent}>
            <img className={styles.king1Icon} alt="" src="../cuore.svg" />
            <h2 className={styles.kingLead} >
              Dottor Udito
            </h2>
            <input
              className={styles.frameChild}
              type="text"
              placeholder="Email..."
              name="email"
              value={dati.email}
              onChange={handleChange}
            />
            <input
              className={styles.frameChild}
              type="password"
              placeholder="Password.."
              name="password"
              value={dati.password}
              onChange={handleChange}

            />
            <button className={styles.textWrapper} onClick={authFunction} >
              <h4 className={styles.text}>Login</h4>
            </button>
          </div>
        </div>

      </LoadingOverlay>
    </>
  );
};

export default Login;
