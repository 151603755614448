import { useState, useCallback, useEffect, useContext } from "react";
import Sidebar from "../components/KingLeadSidebar2";
import PortalDrawer from "../components/PortalDrawer";
import KingLeadSidebar from "../components/KingLeadSidebar";
import LeadMenu from "../components/LeadMenu";
import { auth, Rdb } from "../fire";
import { ref, set, child, get, update, remove } from "firebase/database";
import Swal from 'sweetalert2'

import PopUpData from "../components/PopUpData";
import PortalPopup from "../components/PortalPopup2";




import userData from '../contexts/auth';



import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment';
import 'moment/locale/it';




import 'react-big-calendar/lib/css/react-big-calendar.css';
import styles from "./Home.module.css";

const localizer = momentLocalizer(moment)
const Calendario = () => {
    const [isSidebar1Open, setSidebar1Open] = useState(false);
    const [appuntamenti, setAppuntamenti] = useState([]);
    const [isPopUpDataOpen, setPopUpDataOpen] = useState(false);
    const [value, setValue] = useState({});



    const userdata = useContext(userData)





    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })



    const openSidebar1 = useCallback(() => {
        setSidebar1Open(true);
    }, []);

    const closeSidebar1 = useCallback(() => {

        setSidebar1Open(false);
    }, []);

    useEffect(() => {
        const scrollAnimElements = document.querySelectorAll(
            "[data-animate-on-scroll]"
        );
        const observer = new IntersectionObserver(
            (entries) => {
                for (const entry of entries) {
                    if (entry.isIntersecting || entry.intersectionRatio > 0) {
                        const targetElement = entry.target;
                        targetElement.classList.add(styles.animate);
                        observer.unobserve(targetElement);
                    }
                }
            },
            {
                threshold: 0.15,
            }
        );

        for (let i = 0; i < scrollAnimElements.length; i++) {
            observer.observe(scrollAnimElements[i]);
        }

        return () => {
            for (let i = 0; i < scrollAnimElements.length; i++) {
                observer.unobserve(scrollAnimElements[i]);
            }
        };
    }, []);

    function getData() {
        //get data from db in utenti where appuntamento exists and is not null and set it to the calendar
        get(child(ref(Rdb), `utenti`)).then((snapshot) => {
            if (snapshot.exists()) {
                const app = [];
                //attraverso un for each vedo se esiste l'appuntamento e se è null
                snapshot.forEach((childSnapshot) => {
                    var childData = childSnapshot.val();
                    if (childData.Appuntamento != null) {
                        //declare the start and end date that is start date + 1 hour
                        const date = new Date(childData.Appuntamento);
                        const end = new Date(date.getTime() + 60 * 60000);


                        childData.start = date;
                        childData.end = end;
                        childData.title = childData.Nome;
                        app.push(childData);
                    }
                });
                setAppuntamenti(app);

            } else {
                console.log("No data available");
            }
        }
        ).catch((error) => {
            console.error(error);
        }
        );
    }


    const handleEventClick = event => {
        console.log(event);
        setValue({ row: event });
        setPopUpDataOpen(true);

    };

    useEffect(() => {
        getData();
    }, []);



    const messages = { // new
        allDay: 'Tutto il giorno',
        previous: 'Precedente',
        next: 'Prossimo',
        today: 'Oggi',
        month: 'Mese',
        week: 'Settimana',
        day: 'Giorno',
        agenda: 'Agenda',
        date: 'Data',
        time: 'Ora',
        event: 'Appuntamento',
    };





    return (
        <>
            <div className={styles.home} data-animate-on-scroll>
                <KingLeadSidebar attivo="Calendario" />
                <div className={styles.mainui}>
                    <LeadMenu leadOverviewText="Calendario" openSidebar1={openSidebar1} />
                    <div className={styles.main} >
                        <Calendar
                            localizer={localizer}
                            startAccessor="start"
                            endAccessor="end"
                            style={{ width: "100%" }}
                            messages={messages} // new
                            events={appuntamenti}
                            onSelectEvent={handleEventClick}

                        />
                    </div>
                </div>
            </div>
            {isSidebar1Open && (
                <PortalDrawer
                    overlayColor="rgba(113, 113, 113, 0.3)"
                    placement="Left"
                    onOutsideClick={closeSidebar1}
                >
                    <Sidebar attivo="Utenti" />
                </PortalDrawer>
            )}
            {isPopUpDataOpen && (
                <PortalPopup
                    overlayColor="rgba(113, 113, 113, 0.3)"
                    placement="Centered"
                    onOutsideClick={() => setPopUpDataOpen(false)}
                >

                    <PopUpData onClose={() => setPopUpDataOpen(false)} datiAttività={value} edit={true} />


                </PortalPopup>
            )}



        </>
    );
};

export default Calendario;
