import { useState, useRef, useCallback,useContext } from "react";
import Popovermenu from "../components/Popovermenu";
import PortalPopup from "../components/PortalPopup";
import{BiMenu} from "react-icons/bi"

import userData from '../contexts/auth';

import styles from "./LeadMenu.module.css";

const LeadMenu = ({ leadOverviewText, openSidebar1 }) => {
  const data = useContext(userData)

  const iconaImageRef = useRef(null);
  const [isPopovermenuPopupOpen, setPopovermenuPopupOpen] = useState(false);

  const openPopovermenuPopup = useCallback(() => {
    setPopovermenuPopupOpen(true);
  }, []);

  const closePopovermenuPopup = useCallback(() => {
    setPopovermenuPopupOpen(false);
  }, []);

  return (
    <>
      <div className={styles.topmenu}>
      <BiMenu fontSize={"1.3em"} onClick={openSidebar1} className={styles.ham}/>
        <h2 className={styles.overview} >
          {leadOverviewText}
        </h2>
        <div className={styles.displaylogin}>
          <h4 className={styles.nomelogin}>{data.Nome}</h4>
          <img
            className={styles.icona}
            alt=""
            src="../cuore.svg"
            ref={iconaImageRef}
            onClick={openPopovermenuPopup}
          />
        </div>
      </div>
      {isPopovermenuPopupOpen && (
        <PortalPopup
          placement="Top right"
          relativeLayerRef={iconaImageRef}
          onOutsideClick={closePopovermenuPopup}
        >
          <Popovermenu onClose={closePopovermenuPopup} />
        </PortalPopup>
      )}
    </>
  );
};

export default LeadMenu;
