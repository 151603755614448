import { useState, useCallback, useEffect, useContext } from "react";
import Sidebar from "../components/KingLeadSidebar2";
import PortalDrawer from "../components/PortalDrawer";
import KingLeadSidebar from "../components/KingLeadSidebar";
import LeadMenu from "../components/LeadMenu";
import { DataGrid, itIT, GridActionsCell, GridActionsCellItem } from '@mui/x-data-grid';
import { auth, Rdb } from "../fire";
import { ref, set, child, get, update,remove } from "firebase/database";
import Swal from 'sweetalert2'
import PopUpData from "../components/utenti/modificaUtenti";
import NuovoUtente from "../components/utenti/nuovoUtente";


import PortalPopup from "../components/PortalPopup2";


import userData from '../contexts/auth';

import { AiTwotoneEdit } from 'react-icons/ai';
import { MdRemoveCircle } from 'react-icons/md';
import { FaTasks } from 'react-icons/fa';
import { MdStopCircle } from 'react-icons/md';




import styles from "./Utenti.module.css";

const TabellaUtenti = () => {
  const [isSidebar1Open, setSidebar1Open] = useState(false);
  const [isPopUpDataOpen, setPopUpDataOpen] = useState(false);
  const [nuovoutente, setNuovoutente] = useState(false);
  const [openTask, setOpenTask] = useState(false);
  const [taskID, setTaskId] = useState({});



  const userdata = useContext(userData)


  const [utenti, setUtenti] = useState([]);

  const [value, setValue] = useState([]);



  const [loading, setLoading] = useState(true);



  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  const columns = [
    { field: 'id', headerName: 'ID', width: 150, hide: true },
    {
      field: "actions",
      type: "actions",
      width: 50,
      getActions: (cell) => [
        //Solo un admin può eliminare un admin o modificarlo

        <GridActionsCellItem
          label={"Modifica"}
          icon={<AiTwotoneEdit fontSize={"1.2em"} />}

          onClick={cell.row.Tipo == "admin" ? (
            userdata.Tipo == "admin" ? () => openModifica(cell) : () => Toast.fire({
              icon: 'error',
              title: 'Non hai i permessi per fare ciò'
            }))
            : () => openModifica(cell)}
          showInMenu
        />,
        <GridActionsCellItem
          label={cell.row.sospeso == true ? "Attiva" : "Sospendi"}
          icon={<MdStopCircle fontSize={"1.2em"} />}

          onClick={cell.row.Tipo == "admin" ? (
            userdata.Tipo == "admin" ? () => cell.row.sospeso == true ? attiva(cell.id) : sospendi(cell.id) : () => Toast.fire({
              icon: 'error',
              title: 'Non hai i permessi per fare ciò'
            }))
            : () => cell.row.sospeso == true ? attiva(cell.id) : sospendi(cell.id)}
          showInMenu
        />,
        <GridActionsCellItem
          label={"Rimuovi"}
          icon={<MdRemoveCircle fontSize={"1.2em"} />}

          onClick={cell.row.Tipo == "admin" ? (
            userdata.Tipo == "admin" ? () => rimuoviCampo(cell.id) : () => Toast.fire({
              icon: 'error',
              title: 'Non hai i permessi per fare ciò'
            }))
            : () => rimuoviCampo(cell.id)}

          showInMenu
        />,
      

      ],
    },
    { field: 'Nome', headerName: 'Nome', width: 200 },
    { field: 'Cognome', headerName: 'Cognome', width: 200 },
    { field: 'Email', headerName: 'Email', width: 250 },
    { field: 'Tipo', headerName: 'Ruolo', width: 150 },




  


  ];

  let columnss = [];

  let rows = [];

  const openSidebar1 = useCallback(() => {
    setSidebar1Open(true);
  }, []);

  const closeSidebar1 = useCallback(() => {

    setSidebar1Open(false);
  }, []);
  const openPopUpData = useCallback(() => {
    setPopUpDataOpen(true);
  }, []);

  const closePopUpData = useCallback(() => {
    getData();
    setPopUpDataOpen(false);
  }, []);

  const closePopUpData2 = useCallback(() => {
    getData();
    setNuovoutente(false);
  }, []);

  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add(styles.animate);
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);



  function rimuoviCampo(val) {
    Swal.fire({
      title: "Vuoi rimuovere quest'utente?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Conferma',
      confirmButtonColor: "#28b0b0",
      denyButtonText: `No`,
      reverseButtons: true

    }).then((result) => {

      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        setLoading(true)
        remove(ref(Rdb, "account/"+ val)).then(() => {
          let rows = utenti.filter(item => item.id !== val); //serve per evitare di fare una nuova chiamata al database
          setUtenti(rows)

          setLoading(false)
          Toast.fire({
            icon: 'success',
            title: 'Utente rimosso correttamente'
          })
        })
          .catch((error) => {
            setLoading(false)
            Toast.fire({
              icon: 'error',
              title: 'Errore nella cancellazione'
            })
          })
      }
    });

  }
  function sospendi(val) {
    Swal.fire({
      title: "Vuoi sospendere quest'utente?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Conferma',
      confirmButtonColor: "#28b0b0",
      denyButtonText: `No`,
      reverseButtons: true

    }).then((result) => {

      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        setLoading(true)
        update(ref(Rdb, "account/"+ val), { sospeso: true }).then(() => {

          getData()
          setLoading(false)
          Toast.fire({
            icon: 'success',
            title: 'Utente sospeso correttamente'
          })
        })
          .catch((error) => {
            setLoading(false)
            Toast.fire({
              icon: 'error',
              title: 'Errore nella cancellazione'
            })
          })
      }
    });

  }
  function attiva(val) {
    Swal.fire({
      title: "Vuoi riattivare quest'utente?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Conferma',
      confirmButtonColor: "#28b0b0",
      denyButtonText: `No`,
      reverseButtons: true

    }).then((result) => {

      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        setLoading(true)
        update(ref(Rdb, "account/"+ val), { sospeso: false }).then(() => {

          getData()
          setLoading(false)
          Toast.fire({
            icon: 'success',
            title: 'Utente riattivato correttamente'
          })
        })
          .catch((error) => {
            setLoading(false)
            Toast.fire({
              icon: 'error',
              title: 'Errore nella cancellazione'
            })
          })
      }
    });

  }

  function openModifica(value) {
    setValue(value);
    setPopUpDataOpen(true);


  }
  function task(value) {
    setTaskId(value);
    setOpenTask(true);



  }


  function getData() {
    setLoading(true);
    rows = [];

    get((ref(Rdb, 'account'))).then((snapshot) => {
      Object.values(snapshot.val()).forEach(val => {
        if(val.Tipo !="partner"){
          rows = [...rows, val];
        }
      })
      setLoading(false)
      setUtenti(rows)

    })




  }

  useEffect(() => {
    getData();
  }, []);



  return (
    <>
      <div className={styles.home} data-animate-on-scroll>
        <KingLeadSidebar attivo="Utenti" />
        <div className={styles.mainui}>
          <LeadMenu leadOverviewText="Utenti" openSidebar1={openSidebar1} />
          <div className={styles.main} >
            <button className={styles.textWrapper} onClick={() => setNuovoutente(true)}>
              <h4 className={styles.text}>Nuovo Utente</h4>
            </button>
            {
              loading ?
                <DataGrid
                  rows={rows}
                  columns={columns}
                  pageSize={100}
                  rowsPerPageOptions={[5]}
                  localeText={itIT.components.MuiDataGrid.defaultProps.localeText}

                  sx={{ width: "100%" }}
                  loading={true}

                />
                :
                <DataGrid
                  rows={utenti}
                  columns={columns}
                  pageSize={100}
                  rowsPerPageOptions={[5]}
                  localeText={itIT.components.MuiDataGrid.defaultProps.localeText}

                  sx={{ width: "100%", backgroundColor: "white", borderRadius: 4, minHeight: "80vh" }}
                  loading={loading}
                  rowHeight={50}


                />
            }








          </div>
        </div>
      </div>
      {isSidebar1Open && (
        <PortalDrawer
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Left"
          onOutsideClick={closeSidebar1}
        >
          <Sidebar attivo="Utenti"  />
        </PortalDrawer>
      )}
      {isPopUpDataOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closePopUpData}
        >

          <PopUpData onClose={closePopUpData} datiAttività={value} />


        </PortalPopup>
      )}
      {nuovoutente && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closePopUpData2}
        >

          <NuovoUtente onClose={closePopUpData2} />


        </PortalPopup>
      )}
      {openTask && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={() => setOpenTask(false)}
        >

          <NuovaTask onClose={() => setOpenTask(false)} idUtente={taskID} />


        </PortalPopup>
      )}
    </>
  );
};

export default TabellaUtenti;
