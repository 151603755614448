import { useEffect, useState, useContext } from "react";
import LoadingOverlay from "react-loading-overlay";
import Swal from "sweetalert2";
import userData from "../../contexts/auth";

import { Rdb } from "../../fire";
import { ref, set, child, get, update } from "firebase/database";

import styles from "../PopUpData.module.css";

const PopUpData = ({ onClose, datiAttività }) => {
  const data = useContext(userData);

  const [dati, setDati] = useState(datiAttività.row);
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    const value = event.target.value;
    setDati({ ...dati, [event.target.name]: value });
  };
  function updatedata() {
    Swal.fire({
      title: "Vuoi modificare questa riga?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Conferma",
      confirmButtonColor: "#08174d",
      denyButtonText: `No`,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        const utenteRef = ref(Rdb, "account/" + dati.id);

        update(utenteRef, dati)
          .then(() => {
            setLoading(false);
            onClose();
          })
          .catch((error) => {
            setLoading(false);
            onClose();
          });
      } else {
      }
    });
  }

  return (
    <LoadingOverlay active={loading} spinner>
      <div className={styles.popupdata}>
        <div className={styles.modificaDati}>Modifica Aziende</div>

        <div className={styles.inputsection}>
          <div style={{ flexDirection: "column" }}>
            <h3 className={styles.testo}>Nome</h3>
            <input
              className={styles.inputsectionChild}
              name="Nome"
              defaultValue={dati.Nome || ""}
              type="text"
              placeholder={dati.Nome}
              onChange={handleChange}
            />
          </div>
          <div style={{ flexDirection: "column" }}>
            <h3 className={styles.testo}>Cognome</h3>
            <input
              className={styles.inputsectionChild}
              onChange={handleChange}
              defaultValue={dati.Cognome || ""}
              type="text"
              placeholder={dati.Cognome}
              name="Cognome"
            />
          </div>
          <div style={{ flexDirection: "column" }}>
            <h3 className={styles.testo}>Percentuale</h3>
            <input
              className={styles.inputsectionChild}
              onChange={handleChange}
              defaultValue={dati.Percentuale || ""}
              type="number"
              placeholder={dati.Percentuale}
              name="Percentuale"
            />
          </div>
        </div>

        <div className={styles.frameParent}>
          <button className={styles.textWrapper} onClick={onClose}>
            <h4 className={styles.text}>Annulla</h4>
          </button>
          <button className={styles.textContainer} onClick={() => updatedata()}>
            <h4 className={styles.text}>Conferma</h4>
          </button>
        </div>
      </div>
    </LoadingOverlay>
  );
};

export default PopUpData;
