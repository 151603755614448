import React, { useContext } from "react";
import { useEffect, useState } from "react";

import LoadingOverlay from "react-loading-overlay";
import Swal from "sweetalert2";

import { Rdb } from "../../fire";
import { ref, set, child, get, update, push } from "firebase/database";

import styles from "../PopUpData.module.css";

const NuovoUtente = ({ onClose, onSuccess }) => {
  const jsonData = require("../../poolData/comuni.json");
  const [dati, setDati] = useState({
    Visto: false,
    tipo: "Lead manuale",
    Data: new Date().toString(),
  });
  const [Citta, setCitta] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    const value = event.target.value;
    setDati({ ...dati, [event.target.name]: value });
    if (event.target.name == "Cap") {
      const result = jsonData.find((item) =>
        item.cap.includes(event.target.value)
      );
      if (result) {
        const nome = result.nome;
        const nomeProvincia = result.sigla;
        console.log(nome);
        setCitta(nome + " (" + nomeProvincia + ")");
      } else {
        setCitta("");
      }
    }
  };
  function updatedata() {
    Swal.fire({
      title: "Vuoi aggiungere questo lead?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Conferma",
      confirmButtonColor: "#08174d",
      denyButtonText: `No`,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        const leadRef = ref(Rdb, "utenti");

        push(leadRef, dati)
          .then(() => {
            setLoading(false);
            onClose();
            onSuccess();
          })
          .catch((error) => {
            setLoading(false);
            onClose();
            console.log(error);
          });

        //   })
      } else {
      }
    });
  }

  return (
    <LoadingOverlay active={loading} spinner>
      <div className={styles.popupdata}>
        <div className={styles.modificaDati}>Aggiungi Lead</div>

        <div className={styles.inputsection}>
          <div style={{ flexDirection: "column" }}>
            <h3 className={styles.testo}>Nome</h3>
            <input
              className={styles.inputsectionChild}
              name="Nome"
              value={dati.Nome || ""}
              type="text"
              placeholder="Nome"
              onChange={handleChange}
            />
          </div>
          <div style={{ flexDirection: "column" }}>
            <h3 className={styles.testo}>Numero di telefono</h3>
            <input
              className={styles.inputsectionChild}
              onChange={handleChange}
              defaultValue={dati.Telefono || ""}
              type="text"
              placeholder="Telefono"
              name="Telefono"
            />
          </div>
        </div>
        <div className={styles.inputsection}>
          <div style={{ flexDirection: "column" }}>
            <h3 className={styles.testo}>Età</h3>
            <input
              className={styles.inputsectionChild}
              onChange={handleChange}
              defaultValue={dati.eta || ""}
              type="text"
              placeholder="Età"
              name="Età"
            />
          </div>

          <div style={{ flexDirection: "column" }}>
            <h3 className={styles.testo}>Cap</h3>
            <input
              className={styles.inputsectionChild}
              onChange={handleChange}
              defaultValue={dati.Cap || ""}
              placeholder="Cap"
              name="Cap"
            />
          </div>
        </div>
        <div style={{ flexDirection: "column" }}>
          <h3 className={styles.testo}>Cap</h3>
          <input
            className={styles.inputsectionChild}
            value={Citta || ""}
            placeholder="Citta"
            name="Citta"
          />
        </div>

        <div className={styles.frameParent}>
          <button className={styles.textWrapper} onClick={onClose}>
            <h4 className={styles.text}>Annulla</h4>
          </button>
          <button className={styles.textContainer} onClick={() => updatedata()}>
            <h4 className={styles.text}>Conferma</h4>
          </button>
        </div>
      </div>
    </LoadingOverlay>
  );
};

export default NuovoUtente;
