import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import userData from "../contexts/auth";
import { MdWork } from "react-icons/md";
import { HiUsers } from "react-icons/hi";
import { MdSettings } from "react-icons/md";
import { FaChartPie } from "react-icons/fa";
import { MdOutlineHearing } from "react-icons/md";
import { TbViewfinder } from "react-icons/tb";
import { RiFileAddFill } from "react-icons/ri";
import { MdDomainAdd } from "react-icons/md";
import { MdOutlineAttachMoney } from "react-icons/md";
import { BiArchive } from "react-icons/bi";
import { BsFillCalendarEventFill } from "react-icons/bs";

import styles from "./KingLeadSidebar.module.css";

const KingLeadSidebar = ({ attivo }) => {
  const navigate = useNavigate();
  const data = useContext(userData);

  return (
    <div className={styles.sidebar}>
      <img
        style={{ width: "100%" }}
        alt=""
        src="../dottor-udito--bianco-1.svg"
      />
      <div className={styles.frameParent}>
        <div
          onClick={() => navigate("/")}
          className={
            attivo == "Overview"
              ? styles.icons8RiequilibrareIlPortafParent
              : styles.icons8RiequilibrareIlPortafGroup
          }
        >
          <FaChartPie color="white" fontSize="2.2em" />

          <h4 className={styles.kingLead}>Home</h4>
        </div>

        {data.Tipo != "farmacia" ? (
          <div
            onClick={() => navigate("/calendario")}
            className={
              attivo == "Calendario"
                ? styles.icons8RiequilibrareIlPortafParent
                : styles.icons8RiequilibrareIlPortafGroup
            }
          >
            <BsFillCalendarEventFill color="white" fontSize="2.2em" />
            <h4 className={styles.kingLead}>Calendario</h4>
          </div>
        ) : null}
        {data.Tipo == "admin" || data.Tipo == "amministrazione" ? (
          <div
            onClick={() => navigate("/analytics")}
            className={
              attivo == "Analytics"
                ? styles.icons8RiequilibrareIlPortafParent
                : styles.icons8RiequilibrareIlPortafGroup
            }
          >
            <BiArchive color="white" fontSize="2.2em" />

            <h4 className={styles.kingLead}>Analytics</h4>
          </div>
        ) : null}

        {data.Tipo == "admin" || data.Tipo == "amministrazione" ? (
          <div
            onClick={() => navigate("/otorini")}
            className={
              attivo == "Otorini"
                ? styles.icons8RiequilibrareIlPortafParent
                : styles.icons8RiequilibrareIlPortafGroup
            }
          >
            <MdOutlineHearing color="white" fontSize="2.2em" />

            <h4 className={styles.kingLead}>Otorini</h4>
          </div>
        ) : null}
        {data.Email == "francesco@dottorudito.it" ? (
          <div
            onClick={() => navigate("/farmacie")}
            className={
              attivo == "Farmacie"
                ? styles.icons8RiequilibrareIlPortafParent
                : styles.icons8RiequilibrareIlPortafGroup
            }
          >
            <MdOutlineHearing color="white" fontSize="2.2em" />

            <h4 className={styles.kingLead}>Farmacie</h4>
          </div>
        ) : null}

        {data.Tipo == "admin" || data.Tipo == "amministrazione" ? (
          <div
            onClick={() => navigate("/utenti")}
            className={
              attivo == "Utenti"
                ? styles.icons8RiequilibrareIlPortafParent
                : styles.icons8RiequilibrareIlPortafGroup
            }
          >
            <HiUsers color="white" fontSize="2.2em" />

            <h4 className={styles.kingLead}>Utenti</h4>
          </div>
        ) : null}

        {data.Tipo == "admin" || data.Tipo == "amministrazione" ? (
          <div
            onClick={() => navigate("/aziende")}
            className={
              attivo == "Aziende"
                ? styles.icons8RiequilibrareIlPortafParent
                : styles.icons8RiequilibrareIlPortafGroup
            }
          >
            <MdWork color="white" fontSize="2.2em" />

            <h4 className={styles.kingLead}>Aziende</h4>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default KingLeadSidebar;
