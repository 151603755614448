import React, { useContext } from "react";
import { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import Swal from "sweetalert2";
import DatePicker, { registerLocale } from "react-datepicker";

import it from "date-fns/locale/it"; // the locale you want
registerLocale("it", it);
import Select from "react-select";

import userData from "../../contexts/auth";

import { Rdb } from "../../fire";
import { ref, set, child, get, update, push } from "firebase/database";
import "react-datepicker/dist/react-datepicker.css";
import styles from "../PopUpData.module.css";

const Appuntamento = ({ onClose, datiAttività, onSuccess }) => {
  const data = useContext(userData);
  delete datiAttività?.row?.actions;

  //    Azienda: Persona.id,
  //AziendaNome: Persona.row["Nome Attività"],
  const [dati, setDati] = useState(datiAttività.row);
  const [loading, setLoading] = useState(false);

  const handleChange = event => {
    const value = event.target.value;
    setDati({ ...dati, [event.target.name]: value });
  };

  //push dati in db as appuntamento in /appuntamenti

  function updatedata() {
    Swal.fire({
      title: "Vuoi modificare questa riga?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Conferma",
      confirmButtonColor: "#28b0b0",
      denyButtonText: `No`,
      reverseButtons: true,
    }).then(result => {
      if (result.isConfirmed) {
        setLoading(true);
        const utenteRef = ref(Rdb, "utenti/" + dati.id);
        update(utenteRef, dati)
          .then(() => {
            setLoading(false);
            onClose();
            onSuccess();
          })
          .catch(error => {
            setLoading(false);
            onClose();
          });
      } else {
      }
    });
  }

  //get otorini from db in /users where ruolo = otorino
  const [otorini, setOtorini] = useState([]);
  useEffect(() => {
    const otoriniRef = ref(Rdb, "account");
    get(otoriniRef).then(snapshot => {
      if (snapshot.exists()) {
        let otor = snapshot.val();
        let otoriniDati = [];
        for (let id in otor) {
          console.log(otor[id].Tipo);
          if (otor[id].Tipo == "partner") {
            otoriniDati.push({
              value: otor[id].id,
              label: otor[id].Nome + " " + otor[id].Cognome,
            });
          }
        }
        setOtorini(otoriniDati);
      } else {
        console.log("No data available");
      }
    });
  }, []);

  return (
    <LoadingOverlay active={loading} spinner>
      <div className={styles.popupdata}>
        <div className={styles.modificaDati}>Assegna ad un partner</div>

        <div className={styles.inputsection}>
          <div style={{ flexDirection: "column", minHeight: "50vh", alignContent: "center" }}>
            <h3 className={styles.testo}> Partner</h3>
            <Select
              placeholder="Seleziona Partner"
              className={styles.inputsectionChild2}
              options={otorini}
              onChange={choice =>
                setDati({
                  ...dati,
                  Partner: choice.value,
                  PartnerName: choice.label,
                  PartnerData: new Date(),
                })
              }
              name="Azienda"
              id="Azienda"
            />
          </div>
        </div>

        <div className={styles.frameParent}>
          <button className={styles.textWrapper} onClick={onClose}>
            <h4 className={styles.text}>Annulla</h4>
          </button>
          <button className={styles.textContainer} onClick={() => updatedata()}>
            <h4 className={styles.text}>Conferma</h4>
          </button>
        </div>
      </div>
    </LoadingOverlay>
  );
};

export default Appuntamento;
